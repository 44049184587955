import { collection, getDocs } from "firebase/firestore";
import { iso8601, slugify } from "~/utils/firebase/helpers";

export const useCertificateStore = defineStore("certificate", {
  state: () => ({
    _isLoading: false,
    _certificates: [],
  }),
  getters: {
    isLoading: (state) => state._isLoading,
    certificates: (state) => state._certificates,
  },
  actions: {
    async setup(orgName) {
      this._isLoading = true;
      this._certificates = [];
      const { $db } = useNuxtApp();
      const org = slugify(orgName);
      const path = `organizations/${org}/certificates`;
      await getDocs(collection($db, path).withConverter(iso8601))
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const cert = {
              id: doc.id,
              name: doc.data().name,
              dateCreated: doc.data().dateCreated,
              email: doc.id,
            };
            this.certificates.push(cert);
          });
        })
        .finally(() => {
          this._isLoading = false;
          this.isLoaded = true;
        });
    },
    getByEmail(email) {
      return this.certificates.find((cert) => {
        if (cert.email === email) {
          return cert;
        } else {
          return null;
        }
      });
    },
  },

  persist: {
    enabled: true,
    strategies: [
      {
        key: "certificate",
        storage: persistedState.localStorage,
      },
    ],
  },
});
