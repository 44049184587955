import { defineNuxtPlugin } from '#app/nuxt'
import { LazyButtonLoading, LazyDashboardMain, LazyEasingTransition, LazyEmployeeStatus, LazyFullScreenLoading, LazyGenericButton, LazyGetStartedAside, LazyGetStartedStep, LazyGettingStartedEmptyState, LazyHipaaCertificate, LazyInvitePanel, LazyInviteQueue, LazyModal, LazyModalBackground, LazyModalInvoices, LazyModalNotEnoughSeats, LazyModalResetEmailSent, LazyNavMenuEvent, LazyNavMenuInfo, LazyNavMenuLink, LazyPanelColumn, LazyRightPanel, LazyRoadblockNoSeats, LazySeatsPanel, LazySeatsPanelModal, LazySidebar, LazySpreadsheetUpload, LazyTrackTable, LazyDashSidebar, LazyDashboardDesktopSidebar, LazyDashboardIndicator, LazyDashboardLoadingLine, LazyDashboardMobileSidebar, LazyDashboardSidebar, LazyDashboardStatCounter, LazyDashboardStatCounters, LazyDashboardTableRow, LazyAccount, LazyAuth, LazyCertificate, LazyDashboard, LazyInvitations, LazyInvoice, LazyOrganization, LazyQueue, LazySeat, LazyTokens, LazyUser, LazySvgoDefaultAvatar, LazySvgoHippo, LazySvgoLoading, LazySvgoLogo } from '#components'
const lazyGlobalComponents = [
  ["ButtonLoading", LazyButtonLoading],
["DashboardMain", LazyDashboardMain],
["EasingTransition", LazyEasingTransition],
["EmployeeStatus", LazyEmployeeStatus],
["FullScreenLoading", LazyFullScreenLoading],
["GenericButton", LazyGenericButton],
["GetStartedAside", LazyGetStartedAside],
["GetStartedStep", LazyGetStartedStep],
["GettingStartedEmptyState", LazyGettingStartedEmptyState],
["HipaaCertificate", LazyHipaaCertificate],
["InvitePanel", LazyInvitePanel],
["InviteQueue", LazyInviteQueue],
["Modal", LazyModal],
["ModalBackground", LazyModalBackground],
["ModalInvoices", LazyModalInvoices],
["ModalNotEnoughSeats", LazyModalNotEnoughSeats],
["ModalResetEmailSent", LazyModalResetEmailSent],
["NavMenuEvent", LazyNavMenuEvent],
["NavMenuInfo", LazyNavMenuInfo],
["NavMenuLink", LazyNavMenuLink],
["PanelColumn", LazyPanelColumn],
["RightPanel", LazyRightPanel],
["RoadblockNoSeats", LazyRoadblockNoSeats],
["SeatsPanel", LazySeatsPanel],
["SeatsPanelModal", LazySeatsPanelModal],
["Sidebar", LazySidebar],
["SpreadsheetUpload", LazySpreadsheetUpload],
["TrackTable", LazyTrackTable],
["DashSidebar", LazyDashSidebar],
["DashboardDesktopSidebar", LazyDashboardDesktopSidebar],
["DashboardIndicator", LazyDashboardIndicator],
["DashboardLoadingLine", LazyDashboardLoadingLine],
["DashboardMobileSidebar", LazyDashboardMobileSidebar],
["DashboardSidebar", LazyDashboardSidebar],
["DashboardStatCounter", LazyDashboardStatCounter],
["DashboardStatCounters", LazyDashboardStatCounters],
["DashboardTableRow", LazyDashboardTableRow],
["Account", LazyAccount],
["Auth", LazyAuth],
["Certificate", LazyCertificate],
["Dashboard", LazyDashboard],
["Invitations", LazyInvitations],
["Invoice", LazyInvoice],
["Organization", LazyOrganization],
["Queue", LazyQueue],
["Seat", LazySeat],
["Tokens", LazyTokens],
["User", LazyUser],
["SvgoDefaultAvatar", LazySvgoDefaultAvatar],
["SvgoHippo", LazySvgoHippo],
["SvgoLoading", LazySvgoLoading],
["SvgoLogo", LazySvgoLogo],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
