import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { iso8601, pathFactory, slugify } from "~/utils/firebase/helpers";

export const useOrganizationStore = defineStore("organization", {
  state: () => ({
    _isLoading: false,
    _org: undefined,
    _tasks: undefined,
  }),
  getters: {
    isLoading: (state) => state._isLoading,
    isLoaded: (state) => Boolean(state._org && state._tasks),
    org: (state) => state._org,
    dateCrated: (state) => state._org?.dateCreated,
    dateUpdated: (state) => state._org?.dateUpdated,
    name: (state) => state._org?.name,
    id: (state) => state._org?.id,
    stripCustomerId: (state) => state._org?.stripeCustomerId,
    incompleteTasks: (state) => state._tasks?.filter((task) => !task.completed),
    tasksById: (state) => keyBy(state._tasks, (task) => task.id),
    isPlaceOrderComplete: (getters) => getters.tasksById?.placeorder?.completed,
    isSendInviteComplete: (getters) => getters.tasksById?.sendinvite?.completed,
    isStartTrainingComplete: (getters) => getters.tasksById?.starttraining?.completed,
    isTasksComplete: (getters) =>
      Boolean(getters.isPlaceOrderComplete && getters.isSendInviteComplete),
    freeCourseUrl: (getters) => getters.tasksById?.starttraining?.freeCourseUrl,
  },
  actions: {
    async setupOrganization(orgName) {
      const { $db } = useNuxtApp();
      this._isLoading = true;
      const uid = slugify(orgName);
      const docRef = doc($db, "organizations", uid).withConverter(iso8601);
      await getDoc(docRef)
        .then((doc) => this.$patch({ _org: doc.data() }))
        .finally(() => {
          this._isLoading = false;
        });
    },

    setupToDoTasks(orgName) {
      const { $db } = useNuxtApp();
      this._isLoading = true;
      const orgPath = pathFactory(orgName);
      const colRef = collection($db, orgPath("tasks")).withConverter(iso8601);
      onSnapshot(
        colRef,
        (querySnapshot) => {
          const results = querySnapshot.docs.map((doc) => doc.data());
          this.$patch({ _tasks: results });
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "modified") {
              const index = this._tasks.findIndex((task) => task.id === change.doc.id);
              this._tasks[index] = change.doc.data();
            }
          });
        },
        (err) => {
          throw new Error(err);
        },
      );
    },
    async setup(orgName) {
      await Promise.all([this.setupOrganization(orgName), this.setupToDoTasks(orgName)]);
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "organization",
        storage: persistedState.localStorage,
      },
    ],
  },
});
