import mitt from "mitt";

export default defineNuxtPlugin(() => {
  const emitter = mitt();
  window.bus = emitter;
  return {
    provide: {
      emit: emitter.emit,
      listen: emitter.on,
      unsubscribe: emitter.off,
      listers: emitter.all,
      bus: emitter,
    },
  };
});
