import { collection, onSnapshot, query, where } from "firebase/firestore";
import { defineStore } from "pinia";
import { getInvitations } from "~/utils/api/invitations";

export const useInvitationStore = defineStore("invitations", {
  state: () => ({
    invitations: [],
    isLoading: true,
    isLoaded: false,
  }),
  getters: {
    isInitialized: (state) => state.initialized,
    sentInvitations: (state) => state.invitations.length,
    countInvitations: (state) => state.invitations.length,
    onboardComplete: (state) => state.invitations.length > 0,
    isInvitationStoreLoaded: (state) => state.isLoaded,
    isInvitationStoreLoading: (state) => state.isLoading,
    getByEmail: (state) => (email) => {
      return state.invitations.find((invite) => invite.email === email);
    },
  },
  actions: {
    async setup() {
      const { $db } = useNuxtApp();
      const user = useUserStore();
      this.invitations = await getInvitations();
      this.isLoading = true;

      onSnapshot(
        query(collection($db, "invitations"), where("accountId", "==", user.accountId)),
        (querySnapshot) => {
          const invitations = querySnapshot.docs.map((doc) => doc.data());
          this.$patch({ invitations });
        },
      );
      this.isLoading = false;
      this.isLoaded = true;
      this.initialized = true;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "invitations",
        storage: persistedState.localStorage,
      },
    ],
  },
});
