import { orderBy } from "firebase/firestore";
import { CountQ, Q } from "~/utils/firebase/helpers";

export const useDashboardStore = defineStore("dashboard", {
  state: () => ({
    _isLoading: false,
    _table: undefined,
    _seatCount: undefined,
    _certificateCount: undefined,
    _invitationCount: undefined,
    _inProgressCount: undefined,
  }),
  getters: {
    isLoading: (state) => state._isLoading,
    table: (state) => state._table,
    seatCount: (state) => state._seatCount,
    inProgressCount: (state) => state._inProgressCount,
    certificateCount: (state) => state._certificateCount,
    invitationCount: (state) => state._invitationCount,
    isLoaded: (state) =>
      Boolean(
        state._table && state._seatCount && state._certificateCount && state._invitationCount,
      ),
  },
  actions: {
    async loadTable(orgName) {
      const { $db } = useNuxtApp();
      this._isLoading = true;
      await Q({
        $db,
        orgName,
        path: "dashboard",
        queryParams: [orderBy("status", "asc")],
        data: (data) => {
          this.$patch({ _table: data });
          this._isLoading = false;
        },
        error: (err) => {
          throw new Error(err);
        },
      });
    },
    async loadSeatCount(orgName) {
      const { $db } = useNuxtApp();
      this._isLoading = true;
      await CountQ({
        $db,
        orgName,
        path: "seats",
        data: (count) => {
          this.$patch({ _seatCount: count });
          this._isLoading = false;
        },
        error: (err) => {
          throw new Error(err);
        },
      });
    },
    async loadCertificateCount(orgName) {
      const { $db } = useNuxtApp();
      this._isLoading = true;
      await CountQ({
        $db,
        orgName,
        path: "certificates",
        data: (count) => {
          this.$patch({ _certificateCount: count });
          this._isLoading = false;
        },
        error: (err) => {
          throw new Error(err);
        },
      });
    },
    async loadInvitationCount(orgName) {
      const { $db } = useNuxtApp();
      this._isLoading = true;
      await CountQ({
        $db,
        orgName,
        path: "invitations",
        data: (count) => {
          this.$patch({ _invitationCount: count });
          this._isLoading = false;
        },
        error: (err) => {
          throw new Error(err);
        },
      });
    },
    async loadInProgressCount(orgName) {
      const { $db } = useNuxtApp();
      this._isLoading = true;
      await CountQ({
        $db,
        orgName,
        path: "training",
        data: (count) => {
          this.$patch({ _inProgressCount: count });
          this._isLoading = false;
        },
        error: (err) => {
          throw new Error(err);
        },
      });
    },
    async setup(orgName) {
      await Promise.all([
        this.loadTable(orgName),
        this.loadSeatCount(orgName),
        this.loadCertificateCount(orgName),
        this.loadInvitationCount(orgName),
        this.loadInProgressCount(orgName),
      ]);
    },
  },

  persist: {
    enabled: false,
  },
});
