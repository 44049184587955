import revive_payload_client_ajrVoWjfS5 from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_p6zcxfk5xn7hycsfj5fwd4jbou/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CBpKRoADuH from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_p6zcxfk5xn7hycsfj5fwd4jbou/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_C8M7BPeoos from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_p6zcxfk5xn7hycsfj5fwd4jbou/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_xUMQ1rlwPN from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_p6zcxfk5xn7hycsfj5fwd4jbou/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_dHY4SOVHsU from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_p6zcxfk5xn7hycsfj5fwd4jbou/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_PNx3JmcGHM from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_p6zcxfk5xn7hycsfj5fwd4jbou/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9BoZRr7DUY from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_p6zcxfk5xn7hycsfj5fwd4jbou/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZVpcc2TzG2 from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/nficano/code/teachmehipaa/app/.nuxt/components.plugin.mjs";
import prefetch_client_E5Ju6oSxYU from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_p6zcxfk5xn7hycsfj5fwd4jbou/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_4OAniRrzs0 from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_sEUEKqltXK from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node_5oguorkaiki47zgachagqbgeky/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/Users/nficano/code/teachmehipaa/app/.nuxt/formkitPlugin.mjs";
import plugin_liyViRA2ax from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_rS5xb60Z0v from "/Users/nficano/code/teachmehipaa/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typesc_n7tokq7p5orfvfqa7nvbtjgnzi/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import bus_client_VwprazHOAT from "/Users/nficano/code/teachmehipaa/app/plugins/bus.client.js";
import counter_client_z6WvxtzyKn from "/Users/nficano/code/teachmehipaa/app/plugins/counter.client.js";
import firebase_client_zXNDa0wxFH from "/Users/nficano/code/teachmehipaa/app/plugins/firebase.client.ts";
import lottie_client_funiu1oBjt from "/Users/nficano/code/teachmehipaa/app/plugins/lottie.client.js";
import sentry_client_config_KtZb7e7060 from "/Users/nficano/code/teachmehipaa/app/plugins/sentry.client.config.js";
import session_client_o1l2wpndaC from "/Users/nficano/code/teachmehipaa/app/plugins/session.client.js";
export default [
  revive_payload_client_ajrVoWjfS5,
  unhead_CBpKRoADuH,
  router_C8M7BPeoos,
  payload_client_xUMQ1rlwPN,
  navigation_repaint_client_dHY4SOVHsU,
  check_outdated_build_client_PNx3JmcGHM,
  chunk_reload_client_9BoZRr7DUY,
  plugin_vue3_ZVpcc2TzG2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_E5Ju6oSxYU,
  plugin_client_4OAniRrzs0,
  plugin_sEUEKqltXK,
  formkitPlugin_pZqjah0RUG,
  plugin_liyViRA2ax,
  plugin_rS5xb60Z0v,
  bus_client_VwprazHOAT,
  counter_client_z6WvxtzyKn,
  firebase_client_zXNDa0wxFH,
  lottie_client_funiu1oBjt,
  sentry_client_config_KtZb7e7060,
  session_client_o1l2wpndaC
]