import { default as _91email_93H9Da1vcXLmMeta } from "/Users/nficano/code/teachmehipaa/app/pages/certificate/[email].vue?macro=true";
import { default as dashboardTi18I3kLP3Meta } from "/Users/nficano/code/teachmehipaa/app/pages/dashboard.vue?macro=true";
import { default as forgotn7iLwzjqnBMeta } from "/Users/nficano/code/teachmehipaa/app/pages/forgot.vue?macro=true";
import { default as indexAHhBejfGAwMeta } from "/Users/nficano/code/teachmehipaa/app/pages/index.vue?macro=true";
import { default as loginSYGuxv9doVMeta } from "/Users/nficano/code/teachmehipaa/app/pages/login.vue?macro=true";
import { default as signoutPz6Ccb5KJnMeta } from "/Users/nficano/code/teachmehipaa/app/pages/signout.vue?macro=true";
export default [
  {
    name: "certificate-email",
    path: "/certificate/:email()",
    meta: _91email_93H9Da1vcXLmMeta || {},
    component: () => import("/Users/nficano/code/teachmehipaa/app/pages/certificate/[email].vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/Users/nficano/code/teachmehipaa/app/pages/dashboard.vue")
  },
  {
    name: "forgot",
    path: "/forgot",
    component: () => import("/Users/nficano/code/teachmehipaa/app/pages/forgot.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexAHhBejfGAwMeta || {},
    component: () => import("/Users/nficano/code/teachmehipaa/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/Users/nficano/code/teachmehipaa/app/pages/login.vue")
  },
  {
    name: "signout",
    path: "/signout",
    component: () => import("/Users/nficano/code/teachmehipaa/app/pages/signout.vue")
  }
]