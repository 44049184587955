import { defineStore } from "pinia";
import { getUserProfile } from "~/utils/api/users";

export const useUserStore = defineStore("user", {
  state: () => ({
    accountId: null,
    userType: null,
  }),
  getters: {
    isInitialized: (state) => state.initialized,
    getUser: (state) => state,
  },
  actions: {
    async setup() {
      const profile = await getUserProfile();
      this.accountId = profile.accountId;
      this.userType = profile.userType;
      this.initialized = true;
    },
  },
});
