<template>
  <div>
    <nuxt-layout>
      <nuxt-page />
    </nuxt-layout>
  </div>
</template>
<script setup>
import { getAuth, onAuthStateChanged } from "@firebase/auth";

const currentUserStore = useAuthStore();
const unsubscribe = ref(null);

onMounted(() => {
  const auth = getAuth();
  unsubscribe.value = onAuthStateChanged(auth, (user) => {
    currentUserStore.setUser(user);
  });
});

onUnmounted(() => {
  if (unsubscribe.value) unsubscribe.value();
});

useHead({
  bodyAttrs: {
    class: "h-full",
  },
  htmlAttrs: {
    class: "h-full bg-white",
  },
});
</script>
