import { collection, onSnapshot, query, where } from "firebase/firestore";
import { getInvoices } from "~/utils/api/invoices";

export const useInvoiceStore = defineStore("invoices", {
  state: () => ({
    invoices: [],
    isLoading: true,
    isLoaded: false,
  }),
  getters: {
    hasInvoices: (state) => !!state.invoices.length,
    countInvoices: (state) => state.invoices.length,
    onboardComplete: (state) => state.invoices.length > 0,
    getInvoices: (state) => state.invoices,
    isInitialized: (state) => state.initialized,
    isInvoiceStoreLoaded: (state) => state.isLoaded,
    isInvoiceStoreLoading: (state) => state.isLoading,
  },
  actions: {
    async setup() {
      this.invoices = await getInvoices();
      this.initialized = true;
      this.isLoaded = true;
      this.isLoading = false;
    },
    fetchInvoices(accountId) {
      const { $db } = useNuxtApp();

      onSnapshot(
        query(collection($db, "invoices"), where("accountId", "==", accountId)),
        (querySnapshot) => {
          const invoices = querySnapshot.docs.map((doc) => doc.data());
          this.$patch({ invoices });
        },
      );
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "invoices",
        storage: persistedState.sessionStorage,
      },
    ],
  },
});
