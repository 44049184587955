<template>
  <div>
    <client-only>
      <div v-if="isLoading"><full-screen-loading /></div>
      <div v-if="isAuthenticated">
        <slot></slot>
      </div>
    </client-only>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
const authStore = useAuthStore();
const { isLoading, isAuthenticated } = storeToRefs(authStore);

const router = useRouter();
watchEffect(() => {
  if (!isLoading.value && !isAuthenticated.value) {
    router.push("/login");
  }
});
</script>
