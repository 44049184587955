import { defineStore } from "pinia";
import { getAccount } from "~/utils/api/account";
import { sendInvitations } from "~/utils/api/invitations";

export const useAccountStore = defineStore("account", {
  state: () => ({
    companyName: null,
    id: null,
    seats: null,
    stripeCustomerId: null,
  }),
  getters: {
    isInitialized: (state) => state.initialized,
    getAccount: (state) => state,
  },
  actions: {
    async setup() {
      const account = await getAccount();
      this.companyName = account.companyName;
      this.id = account.accountId;
      this.seats = account.seats;
      this.stripeCustomerId = account.seats;
    },
    async sendEmailInvitations(emails) {
      return await sendInvitations(emails);
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "account",
        storage: persistedState.localStorage,
      },
    ],
  },
});
